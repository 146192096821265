.pricing_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 0;
}

.package_tabs {
  padding: 5px;
  background: #d6e6f3;
  border: 0;
  border-radius: 1.5rem;
}

.package_tabs .ant-card-head {
  border: 0;
  padding: 0;
  min-height: unset;
  margin: 0;
}

.package_tabs .ant-card-head .ant-tabs,
.package_tabs .ant-tabs-tab {
  margin: 0;
}

.package_tabs .ant-tabs-top>.ant-tabs-nav::before,
.package_tabs .ant-tabs-bottom>.ant-tabs-nav::before,
.package_tabs .ant-tabs-top>div>.ant-tabs-nav::before,
.package_tabs .ant-tabs-bottom>div>.ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 0;
  content: '';
}

.package_tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #6301f2;
  pointer-events: none;
  height: 0 !important;
}

.package_tabs .ant-tabs-top>.ant-tabs-nav,
.package_tabs .ant-tabs-bottom>.ant-tabs-nav,
.package_tabs .ant-tabs-top>div>.ant-tabs-nav,
.package_tabs .ant-tabs-bottom>div>.ant-tabs-nav {
  margin: 0;
}

.package_tabs .ant-tabs-large>.ant-tabs-nav .ant-tabs-tab {
  padding: 5px 20px;
}

.package_tabs .ant-tabs-tab.ant-tabs-tab-active {
  background: #ffffff;
  border-radius: 1.5rem;
}
.free_trial{
  text-align: center;
}

@media screen and (max-width: 991px) {
  
  .free_trial img{
    max-width: 130px !important;
    margin-bottom: 20px;
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 575px) {
  .pricing_head {
    flex-direction: column-reverse;
    row-gap: 20px;
  }
  .users_count{
    justify-content: center;
  }
  .pricing_area{
    text-align: center;
    margin-bottom: 20px;
  }
}

/* style for webview only */
.pricing_head-webview .user_selection {
  margin-top: 20px;
}

.pricing_head-webview .user_selection h3 {
  margin-right: 20px !important;
}

.pricing_head-webview .package_tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: none !important;
}

.button-webview {
  border-radius: 1.2rem;
  background: #8629ff;
  border-color: #8629ff;
  width: 100%;
}